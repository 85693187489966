div {
  &.DraftEditor-root {
    border: 1px solid #a4a4a4;
    height: 200px;
    min-height: 200px;
    width: 100%;
    overflow-y: auto;
    resize: vertical;
  }
  &.DraftEditor-editorContainer,
  &.public-DraftEditor-content {
    height: 100%;
  }
  &.public-DraftEditor-content {
    font-size: 14px;
    padding: 13px;
  }
  &.public-DraftEditorPlaceholder-root {
    font-size: 14px;
    padding: 13px;
    font-style: italic;
    color: #a4a4a4;
    position: absolute;
  }
}
